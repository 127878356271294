import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ComingSoon from "../components/ComingSoon"
import GoogleAdsense from "../components/GoogleAdsense"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="liên hệ" />
      <ComingSoon />
      <GoogleAdsense
        client="ca-pub-1804168523618722"
        slot="3767743010"
        className="google-ads-footer"
      />
    </Layout>
  )
}

export default ContactPage
